<template>
  <div class="page">
    <div class="segment">
      <div class="content narrow">
        <!-- <div class="header">BERGET 19 <span class="text-highlight">STORY</span></div> -->
        <!-- POST 1 -->
        <div class="post column">
          <div class="post-banner">
            <img src="@/assets/pagebanner-story.jpg">
          </div>
          <div class="post-content-wrapper">

            <div class="post-header">Background</div>
            <pre class="post-content">
              The fighting continues in the small nation of Langoi. While the Coalition Forces fight for every meter of land in the region, the Republican Army also suffered big losses while fighting the much larger opposing force .

              The Coalition Forces are still hunting the infamous prison general. According to the Langoi government, once he is captured and put to trial, the atrocities in prison committed by the Republican Army leaders will be made public.

              While the Republican Army and the Coalition Forces fight for supremacy in the region, the Resistance managed to gain control of a military weapons supplybase after a successful raid against the facility far behind  Republican Army forces.

              The facility contains advanced military equipment such as mid range mobile ballistic missiles, ammunition storage and tanks & armored vehicles. The facility also contains a concrete underground storage bunker named “T8”. The storage is only accessible to high ranking officials and its contents are classified to the world.

              The Resistance have been spotted selling weapons and ammunition that have been confirmed to have originated from the facility. The affairs take place in a fortified heavily guarded market that is run by Resistance members. The market is located in a neutral zone where none of the forces have control and the guards are equipped to keep the peace inside. The weapons depot is also of great interest to the Coalition and they have deployed special-forces to gain access to the weapons depot.

              The region is infested by a violent gang of raiders who terrorize the area with kidnappings, robbery and drug dealing. They have been known as guns for hire and there are rumors that some of the members are ex-military, escaped prisoners and even ex-patients of the abandoned asylum.

              The Republican army is now forced to dispatch its best battalion into the area to gain control of the heavily fortified supply base and to keep it out of the Coalition's hands.

              While the Resistance gains funds and military power, the race stands between the Coalition forces and the Republic army - who will control the weapons facility and storage of T8?
            </pre>

          </div>

        </div>

        <div class="poster">
          <img src="../assets/splash.jpg">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story'
}
</script>
